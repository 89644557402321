
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import Layout from "@Containers/LayoutV2";
import useTranslation from "next-translate/useTranslation";

function NotFound() {
  const { t } = useTranslation("common");

  return (
    <Layout seoData={{ title: t("404-title") }}>
      <br />
      <h3 style={{ margin: "60px 0", textAlign: "center" }}>Page Not Found</h3>
    </Layout>
  );
}

export default NotFound;


    export async function getStaticProps(ctx) {
        
        
        return {
          
          
          props: {
            
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/404',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  